import React, { useEffect, useState } from "react";


const Crochet = () => {
  
//   var pixels = []
//   var rows;

//   for (let i = 0; i < 80; i++) {
//     var pixel = data[i]
//     pixels.push(pixel)
//   }

//   var lastPixel;
//   var n = 0;
//   var row = document.createElement("TR");
//   pixels.forEach(pixel => {
//     if (lastPixel == null) {
//       lastPixel = pixel
//       n++;
//     }
//     else {
//       if (pixel.colour == lastPixel.colour && pixel.y == lastPixel.y) {
//         n++;
//       } else {
//         //Must be a new colour so record number of last colour
//         var cell = document.createElement("TD");
//         cell.appendChild(document.createTextNode(n))
//         cell.style.backgroundColor = 
//           `rgba(${pixel.colour.R},
//           ${pixel.colour.G},
//           ${pixel.colour.B},
//           ${pixel.colour.A})`;
//         n = 0;
//         row.appendChild(cell)
//       }
//     }
//   })

// rows.push(row)

//   pixels.forEach(pixel => {
//     var r, g, b = pixel
//     console.log(r)
//     console.log(g)
//     console.log(b)
// }) 
//   .forEach(coord => {
//       var x = coord.split(" ")[0]
//       var y = coord.split(" ")[1]
//       console.log('x = ' + x + ' y = ' + y)
//   })
// })

// return (
//   <div>
//     <table>
//         <tr>

//         </tr>

//         {/* {rows.map((val, key) => {
//                     return (
//                         <tr key={key}>
//                             <td>{val.cell}</td>
//                         </tr>
//                     )
//                 })} */}

//     </table>
//   </div>
// );

// return (<>{JSON.stringify(data)}</>)

}

export default Crochet;

